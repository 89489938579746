import { Box, Container, Heading, Text } from '@chakra-ui/react'

import Layout from '../components/Base/Layout'
import React from 'react'

function FAQ() {

    const content = [
        {
            q: "What are your hours of operation?",
            a: "Our hours of operation are Monday to Friday 9:00 to 5:00 eastern standard time."
        }, {
            q: "What currency are your prices quoted in?",
            a: "The prices on our site are in CAD, but we can also quote and take payment in USD."
        }, {
            q: "What method of payments do you accept?",
            a: "We accept Visa, MasterCard, American Express and wire transfer."
        }, {
            q: "What is the cost of shipping?",
            a: "We quote shipping on a per order basis taking into account location and order quantity."
        }, {
            q: "What if I am unable to find the product I want?",
            a: "Please call and ask our customer service team, they would be more than happy to check the availability of the requested product for you."
        }, {
            q: "What if another company has a cheaper price?",
            a: "We work hard to guarantee you the lowest prices. In the case you find a cheaper price, please call or write us and we will do our absolute best to beat or meet it. Please note that we will require proof of the price and service being offered in order to verify the contents of the offer."
        }, {
            q: "Do you accept returns?",
            a: "Yes we do, but you must contact us first to receive approval for your return and will only be accepted if you have been shipped the wrong product, if it was not as you ordered or was damaged in transportation."
        }, {
            q: "When will I get my order?",
            a: "This varies depending on where you are but on average you should receive your order within 2-5 days."
        }
    ]
  return (
      <Layout>
          <Container
              py="200px"
              maxW="7xl"
          >
              <Heading pb="32px" color="#131F36" fontSize="48px">Frequently Asked Questions</Heading>    
              <Box>
                  
                  {content.map((item) => {
                      return   <Box pb="16px">
                      <Heading color="cdicyan.600" fontSize="24px"> {item.q}</Heading>
                          <Text fontSize="16px">{item.a}</Text>
                    </Box>
                  })}

              </Box>
        </Container>
      </Layout>
  )
}

export default FAQ